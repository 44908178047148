<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="relative">
    <div class="vx-navbar-wrapper" :class="classObj">
      <vs-navbar
        class="vx-navbar navbar-custom navbar-skelton"
        :color="navbarColorLocal"
        :class="textColor"
      >
        <!-- <router-link
          tag="div"
          to="/"
          class="vx-logo cursor-pointer mx-auto flex items-center"
        > -->
        <logo class="fill-current text-primary" />
        <!-- </router-link> -->
        <!-- SM - OPEN SIDEBAR BUTTON -->
        <!-- <feather-icon class="sm:inline-flex xl:hidden cursor-pointer p-2" icon="MenuIcon" @click.stop="showSidebar" /> -->

        <!-- <h2 class="font-semibold page-head-title">{{pageTitle}}</h2> -->

        <vs-spacer />
        <div class="text-center flex items-center partner-navbar">
          <a
            @click="
              $router.push({
                name: 'partner-dashboard',
              })
            "
            class="text-sm lg:text-base"
            :class="{ 'nav-link-active': $route.name === 'partner-dashboard' }"
            >Home</a
          >

          <a
            @click="
              $router.push({
                name: 'payment-activity',
              })
            "
            class="text-sm lg:text-base"
            :class="{ 'nav-link-active': $route.name === 'payment-activity' }"
            >Payment activity</a
          >

          <a
            v-if="loggedInUser && loggedInUser.userType !== 'staff'"
            @click="
              $router.push({
                name: 'settings',
              })
            "
            class="text-sm lg:text-base"
            :class="{ 'nav-link-active': $route.name === 'settings' }"
            >Settings</a
          >

          <a
            v-if="loggedInUser && loggedInUser.userType !== 'staff'"
            @click="
              $router.push({
                name: 'help',
              })
            "
            class="text-sm lg:text-base"
            :class="{ 'nav-link-active': $route.name === 'help' }"
          >Help</a
          >

        </div>
        <profile-drop-down />
      </vs-navbar>
    </div>
  </div>
</template>

<script>
import NotificationDropDown from "./components/NotificationDropDown.vue";
import ProfileDropDown from "./components/ProfileDropDown.vue";
import Logo from "../Logo.vue";
export default {
  name: "the-navbar-vertical",
  props: {
    navbarColor: {
      type: String,
      default: "#fff",
    },
  },
  components: {
    NotificationDropDown,
    ProfileDropDown,
    Logo,
  },
  computed: {
    loggedInUser() {
      return JSON.parse(localStorage.getItem("userInfo"));
    },
    pageTitle() {
      return this.$route.meta.pageTitle;
    },
    navbarColorLocal() {
      return this.$store.state.theme === "dark" && this.navbarColor === "#fff"
        ? "#10163a"
        : this.navbarColor;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    textColor() {
      return {
        "text-white":
          (this.navbarColor != "#10163a" &&
            this.$store.state.theme === "dark") ||
          (this.navbarColor != "#fff" && this.$store.state.theme !== "dark"),
      };
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },

    // NAVBAR STYLE
    classObj() {
      if (this.verticalNavMenuWidth == "default") return "navbar-default";
      else if (this.verticalNavMenuWidth == "reduced") return "navbar-reduced";
      else if (this.verticalNavMenuWidth) return "navbar-full";
      else return "navbar-default";
    },
  },
  methods: {
    showSidebar() {
      this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", true);
    },
  },
};
</script>
