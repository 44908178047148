var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative"},[_c('div',{staticClass:"vx-navbar-wrapper",class:_vm.classObj},[_c('vs-navbar',{staticClass:"vx-navbar navbar-custom navbar-skelton",class:_vm.textColor,attrs:{"color":_vm.navbarColorLocal}},[_c('logo',{staticClass:"fill-current text-primary"}),_c('vs-spacer'),_c('div',{staticClass:"text-center flex items-center partner-navbar"},[_c('a',{staticClass:"text-sm lg:text-base",class:{ 'nav-link-active': _vm.$route.name === 'partner-dashboard' },on:{"click":function($event){return _vm.$router.push({
              name: 'partner-dashboard',
            })}}},[_vm._v("Home")]),_c('a',{staticClass:"text-sm lg:text-base",class:{ 'nav-link-active': _vm.$route.name === 'payment-activity' },on:{"click":function($event){return _vm.$router.push({
              name: 'payment-activity',
            })}}},[_vm._v("Payment activity")]),(_vm.loggedInUser && _vm.loggedInUser.userType !== 'staff')?_c('a',{staticClass:"text-sm lg:text-base",class:{ 'nav-link-active': _vm.$route.name === 'settings' },on:{"click":function($event){return _vm.$router.push({
              name: 'settings',
            })}}},[_vm._v("Settings")]):_vm._e(),(_vm.loggedInUser && _vm.loggedInUser.userType !== 'staff')?_c('a',{staticClass:"text-sm lg:text-base",class:{ 'nav-link-active': _vm.$route.name === 'help' },on:{"click":function($event){return _vm.$router.push({
              name: 'help',
            })}}},[_vm._v("Help")]):_vm._e()]),_c('profile-drop-down')],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }